@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

.zipcode-overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
}

.zipcode-overlay__visible {
    opacity: 1;
}

.zipcode-spinner {
    --color: 255, 255, 255;
    --size: min(12vw, 12vh);
    --ring-width: calc(var(--size) / 6);
    --ring-color: rgba(var(--color), 0.3);
    --thumb-color: rgba(var(--color));
    position: relative;
    border-top: var(--ring-width) solid var(--ring-color);
    border-right: var(--ring-width) solid var(--ring-color);
    border-bottom: var(--ring-width) solid var(--ring-color);
    border-left: var(--ring-width) solid var(--thumb-color);
    transform: translateZ(0);
    animation: spin 1.1s infinite linear;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
