.Login {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Login [data-amplify-container] {
    border: solid 1px;
    border-color: rgba(255, 255, 255, 0.23);
    border-radius: 4px;
    overflow: hidden;
}

.Login-logo {
    padding-top: 24px;
    margin: 0 auto;
    text-align: center;
}
